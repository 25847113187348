<template>
  <div>
    <v-card class="d-flex justify-end mb-6" flat tile>
      <v-breadcrumbs :items="breadcrumbs" large>
        <template v-slot:divider>
          <v-icon>{{ icons.mdiSlashForward }} </v-icon>
        </template>
      </v-breadcrumbs>
    </v-card>
    <v-card flat class="pa-3 mt-2">
      <!-- Modal -->
      <div id="exampleModal" class="modal fade" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 id="exampleModalLabel" class="modal-title">KATEGORI MEMBER</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <v-text-field
                v-model="search"
                class="pt-5"
                label="Search"
                dense
                outlined
                @keyup="doSearch()"
              ></v-text-field>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-uppercase">NO</th>
                      <th class="text-center text-uppercase">NAMA KATEGORI MEMBER</th>
                      <th class="text-center text-uppercase">ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(data, index) in displayData()" :key="index">
                      <td>{{ index + 1 }}</td>
                      <td class="text-center">
                        {{ data.nama_kategorimember | capitalize }}
                      </td>

                      <td class="text-center">
                        <v-btn
                          data-bs-dismiss="modal"
                          color="primary"
                          class="me-3 mt-4"
                          @click="PilihKategoriMember(data.id_kategorimember, data.nama_kategorimember)"
                        >
                          <v-icon color="#ECEFF1">
                            {{ icons.mdiCheckBold }}
                          </v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="6">
                        <center>
                          <div class="v-data-footer">
                            <!-- <div class="v-data-footer__pagination" bis_skin_checked="1">1-10 of 100</div> -->
                            <span class="v-data-footer__icons-before" bis_skin_checked="1">
                              <button
                                type="button"
                                :class="[
                                  'v-btn  v-btn--icon v-btn--round v-btn--text theme--light v-size--default',
                                  page == 1 ? 'v-btn--disabled' : '',
                                ]"
                                aria-label="Previous page"
                                @click="page--"
                              >
                                <span class="v-btn__content"
                                  ><span aria-hidden="true" class="v-icon notranslate theme--light"
                                    ><svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      role="img"
                                      aria-hidden="true"
                                      class="v-icon__svg"
                                    >
                                      <path
                                        d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"
                                      ></path></svg></span
                                ></span>
                              </button>
                            </span>
                            <span
                              v-for="pageNumber in pages.slice(page - 1, page + 5)"
                              :key="pageNumber"
                              :class="['v-data-footer', pageNumber == page ? 'active' : '']"
                              @click="page = pageNumber"
                            >
                              <!-- <a href="#">{{ pageNumber }}</a> -->
                              <button
                                type="button"
                                class="v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default"
                                aria-label="Next page"
                              >
                                {{ pageNumber }}
                              </button>
                            </span>
                            <span class="v-data-footer__icons-after" bis_skin_checked="1">
                              <button
                                type="button"
                                :class="[
                                  'v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default',
                                  page < pages.length ? '' : 'v-btn--disabled',
                                ]"
                                aria-label="Next page"
                                @click="page++"
                              >
                                <span class="v-btn__content"
                                  ><span aria-hidden="true" class="v-icon notranslate theme--light"
                                    ><svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      role="img"
                                      aria-hidden="true"
                                      class="v-icon__svg"
                                    >
                                      <path
                                        d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"
                                      ></path></svg></span
                                ></span>
                              </button>
                            </span>
                          </div>
                        </center>
                      </td>
                    </tr>
                  </tfoot>
                </template>
              </v-simple-table>
            </div>
          </div>
        </div>
      </div>
      <!-- end Modal -->

      <!-- Modal -->
      <div id="ModalKelurahan" class="modal fade" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 id="exampleModalLabel" class="modal-title">KELURAHAN</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <v-text-field
                v-model="searchNegaraAsal"
                class="pt-5"
                label="Search"
                dense
                outlined
                @keyup="doSearchNegaraAsal()"
              ></v-text-field>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-uppercase">NO</th>
                      <th class="text-center text-uppercase">NAMA KELURAHAN</th>
                      <th class="text-center text-uppercase">ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(data, index) in displayDataKeluarahan()" :key="index">
                      <td>{{ index + 1 }}</td>
                      <td class="text-center">
                        {{ data.nama_kelurahan | capitalize }}
                      </td>
                      <td class="text-center">
                        <v-btn
                          data-bs-dismiss="modal"
                          color="primary"
                          class="me-3 mt-4"
                          @click="PilihKelurahan(data.id_kelurahan, data.nama_kelurahan)"
                        >
                          <v-icon color="#ECEFF1">
                            {{ icons.mdiCheckBold }}
                          </v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="6">
                        <center>
                          <div class="v-data-footer">
                            <div class="v-data-footer__icons-before" bis_skin_checked="1">
                              <button
                                type="button"
                                :class="[
                                  'v-btn  v-btn--icon v-btn--round v-btn--text theme--light v-size--default',
                                  pageNegaraAsal == 1 ? 'v-btn--disabled' : '',
                                ]"
                                aria-label="Previous page"
                                @click="pageNegaraAsal--"
                              >
                                <span class="v-btn__content"
                                  ><span aria-hidden="true" class="v-icon notranslate theme--light"
                                    ><svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      role="img"
                                      aria-hidden="true"
                                      class="v-icon__svg"
                                    >
                                      <path
                                        d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"
                                      ></path></svg></span
                                ></span>
                              </button>
                            </div>
                            <div
                              v-for="pageNumber in pagesNegaraAsal.slice(pageNegaraAsal - 1, pageNegaraAsal + 5)"
                              :key="pageNumber"
                              :class="['v-data-footer', pageNumber == pageNegaraAsal ? 'active' : '']"
                              @click="pageNegaraAsal = pageNumber"
                            >
                              <!-- <a href="#">{{ pageNumber }}</a> -->
                              <button
                                type="button"
                                class="v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default"
                                aria-label="Next page"
                              >
                                {{ pageNumber }}
                              </button>
                            </div>
                            <div class="v-data-footer__icons-after" bis_skin_checked="1">
                              <button
                                type="button"
                                :class="[
                                  'v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default',
                                  pageNegaraAsal < pagesNegaraAsal.length ? '' : 'v-btn--disabled',
                                ]"
                                aria-label="Next page"
                                @click="pageNegaraAsal++"
                              >
                                <span class="v-btn__content"
                                  ><span aria-hidden="true" class="v-icon notranslate theme--light"
                                    ><svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      role="img"
                                      aria-hidden="true"
                                      class="v-icon__svg"
                                    >
                                      <path
                                        d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"
                                      ></path></svg></span
                                ></span>
                              </button>
                            </div>
                          </div>
                        </center>
                      </td>
                    </tr>
                  </tfoot>
                </template>
              </v-simple-table>
            </div>
          </div>
        </div>
      </div>
      <!-- end Modal -->

      <!-- Modal Kelurahan Toko-->
      <div
        id="ModalKelurahanToko"
        class="modal fade"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 id="exampleModalLabel" class="modal-title">KELURAHAN TOKO</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <v-text-field
                v-model="searchKelurahanToko"
                class="pt-5"
                label="Search"
                dense
                outlined
                @keyup="doSearchKelurahanToko()"
              ></v-text-field>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-uppercase">NO</th>
                      <th class="text-center text-uppercase">NAMA KELURAHAN</th>
                      <th class="text-center text-uppercase">ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(data, index) in displayDataKeluarahanToko()" :key="index">
                      <td>{{ index + 1 }}</td>
                      <td class="text-center">
                        {{ data.nama_kelurahan | capitalize }}
                      </td>
                      <td class="text-center">
                        <v-btn
                          data-bs-dismiss="modal"
                          color="primary"
                          class="me-3 mt-4"
                          @click="PilihKelurahanToko(data.id_kelurahan, data.nama_kelurahan)"
                        >
                          <v-icon color="#ECEFF1">
                            {{ icons.mdiCheckBold }}
                          </v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="6">
                        <center>
                          <div class="v-data-footer">
                            <!-- <div class="v-data-footer__pagination" bis_skin_checked="1">1-10 of 100</div> -->
                            <div class="v-data-footer__icons-before" bis_skin_checked="1">
                              <button
                                type="button"
                                :class="[
                                  'v-btn  v-btn--icon v-btn--round v-btn--text theme--light v-size--default',
                                  pageKelurahanToko == 1 ? 'v-btn--disabled' : '',
                                ]"
                                aria-label="Previous page"
                                @click="pageKelurahanToko--"
                              >
                                <span class="v-btn__content"
                                  ><span aria-hidden="true" class="v-icon notranslate theme--light"
                                    ><svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      role="img"
                                      aria-hidden="true"
                                      class="v-icon__svg"
                                    >
                                      <path
                                        d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"
                                      ></path></svg></span
                                ></span>
                              </button>
                            </div>
                            <div
                              v-for="pageNumber in pagesKelurahanToko.slice(
                                pageKelurahanToko - 1,
                                pageKelurahanToko + 5,
                              )"
                              :key="pageNumber"
                              :class="['v-data-footer', pageNumber == pageKelurahanToko ? 'active' : '']"
                              @click="pageKelurahanToko = pageNumber"
                            >
                              <!-- <a href="#">{{ pageNumber }}</a> -->
                              <button
                                type="button"
                                class="v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default"
                                aria-label="Next page"
                              >
                                {{ pageNumber }}
                              </button>
                            </div>
                            <div class="v-data-footer__icons-after" bis_skin_checked="1">
                              <button
                                type="button"
                                :class="[
                                  'v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default',
                                  pageKelurahanToko < pagesKelurahanToko.length ? '' : 'v-btn--disabled',
                                ]"
                                aria-label="Next page"
                                @click="pageKelurahanToko++"
                              >
                                <span class="v-btn__content"
                                  ><span aria-hidden="true" class="v-icon notranslate theme--light"
                                    ><svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      role="img"
                                      aria-hidden="true"
                                      class="v-icon__svg"
                                    >
                                      <path
                                        d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"
                                      ></path></svg></span
                                ></span>
                              </button>
                            </div>
                          </div>
                        </center>
                      </td>
                    </tr>
                  </tfoot>
                </template>
              </v-simple-table>
            </div>
          </div>
        </div>
      </div>
      <!-- end Modal -->

      <v-card-text>
        <v-card-title class="text-no-wrap pt-1 ps-2"> CREATE MEMBER </v-card-title>
        <form ref="form" enctype="multipart/form-data" @submit.prevent="CreateData">
          <v-row>
            <v-col v-if="errors.length" cols="12">
              <v-alert v-for="error in errors" :key="error" color="error" text class="mb-0">
                <div class="d-flex align-start">
                  <v-icon color="warning"> </v-icon>
                  <div class="ms-3">
                    <p class="text-base font-weight-medium mb-1">
                      {{ error }}
                    </p>
                  </div>
                </div>
              </v-alert>
            </v-col>

            <v-col md="12" cols="12">
              <v-header>Jenis Usaha</v-header>
              <v-radio-group v-model="driver.id_jenisusaha" row>
                <v-radio
                  v-for="(ju, index) in jenisusaha"
                  :key="index"
                  :label="ju.nama_jenisusaha | capitalize"
                  :value="ju.id_jenisusaha"
                  color="info"
                  @click="ClearData()"
                ></v-radio>
              </v-radio-group>
            </v-col>

            <!-- Driver -->
            <v-col md="6" cols="12" v-if="driver.id_jenisusaha == '1a2040e8-c61f-4d66-a93e-057320e1ee6d'">
              <v-text-field
                v-model="driver.no_platmotor"
                label="Nomor Plat Kendaraan"
                class="required"
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col md="6" cols="12" v-if="driver.id_jenisusaha == '1a2040e8-c61f-4d66-a93e-057320e1ee6d'">
              <v-combobox solo v-model="driver.merk_kendaraan" :items="merkmotor" label="Merk Kendaraan"> </v-combobox>
            </v-col>
            <v-col md="6" cols="12" v-if="driver.id_jenisusaha == '1a2040e8-c61f-4d66-a93e-057320e1ee6d'">
              <v-text-field
                v-model="driver.tahun_pembuatan_kendaraan"
                label="Tahun Pembuatan"
                class="required"
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col md="6" cols="12" v-if="driver.id_jenisusaha == '1a2040e8-c61f-4d66-a93e-057320e1ee6d'">
              <v-text-field
                v-model="driver.no_rekening"
                label="Nomor Rekening"
                class="required"
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col md="6" cols="12" v-if="driver.id_jenisusaha == '1a2040e8-c61f-4d66-a93e-057320e1ee6d'">
              <v-text-field v-model="driver.nama_bank" label="Nama Bank" class="required" dense outlined></v-text-field>
            </v-col>

            <v-col md="6" cols="12" v-if="driver.id_jenisusaha == '1a2040e8-c61f-4d66-a93e-057320e1ee6d'">
              <v-header>Aktif</v-header>
              <v-radio-group v-model="driver.aktif" row>
                <v-radio label="Ya" value="Y" color="info"></v-radio>
                <v-radio label="Tidak" value="T" color="error"></v-radio>
              </v-radio-group>
            </v-col>

            <v-col md="6" cols="12" v-if="driver.id_jenisusaha == '1a2040e8-c61f-4d66-a93e-057320e1ee6d'">
              <v-header>Blokir</v-header>
              <v-radio-group v-model="driver.blokir" row>
                <v-radio label="Ya" value="Y" color="info"></v-radio>
                <v-radio label="Tidak" value="T" color="error"></v-radio>
              </v-radio-group>
            </v-col>

            <v-col md="12" cols="12" v-if="driver.id_jenisusaha == '1a2040e8-c61f-4d66-a93e-057320e1ee6d'">
              <v-card-text class="text-no-wrap pt-1 ps-1"> Photo SIM </v-card-text>
              <div class="cropper-area">
                <div class="img-cropper">
                  <vue-cropper ref="cropperSim" :aspect-ratio="4 / 2" :src="showPhotoSim()" preview=".preview" />
                </div>
              </div>

              <v-btn type="submit" color="primary" class="me-3 mt-4" @click.prevent="showFileChooserSim">
                Choose Image
              </v-btn>
              <v-btn type="submit" color="primary" class="me-3 mt-4" @click.prevent="cropImageSim"> Crop </v-btn>

              <input
                ref="inputSim"
                style="display: none"
                type="file"
                :name="driver.photo_sim"
                accept="image/*"
                @change="setImageSim"
              />
              <br />
              <br />
              <v-card-text class="text-no-wrap pt-1 ps-1"> Crop Photo SIM</v-card-text>
              <section class="preview-area">
                <div class="cropped-image">
                  <img
                    width="200px"
                    :src="driver.photo_sim == '' ? defautlPhoto() : driver.photo_sim"
                    alt="Cropped Image"
                  />
                </div>
              </section>
            </v-col>
            <hr />
            <v-col md="12" cols="12" v-if="driver.id_jenisusaha == '1a2040e8-c61f-4d66-a93e-057320e1ee6d'">
              <v-card-text class="text-no-wrap pt-1 ps-1"> Photo Bawa SIM </v-card-text>
              <div class="cropper-area">
                <div class="img-cropper">
                  <vue-cropper
                    ref="cropperBawaSim"
                    :aspect-ratio="4 / 2"
                    :src="showPhotoBawaSim()"
                    preview=".preview"
                  />
                </div>
              </div>

              <v-btn type="submit" color="primary" class="me-3 mt-4" @click.prevent="showFileChooserBawaSim">
                Choose Image
              </v-btn>
              <v-btn type="submit" color="primary" class="me-3 mt-4" @click.prevent="cropImageBawaSim"> Crop </v-btn>
              <input
                ref="inputBawaSim"
                style="display: none"
                type="file"
                :name="driver.photo_dengan_sim"
                accept="image/*"
                @change="setImageBawaSim"
              />
              <br />
              <br />
              <v-card-text class="text-no-wrap pt-1 ps-1"> Crop Photo Bawa SIM</v-card-text>
              <section class="preview-area">
                <div class="cropped-image">
                  <img
                    width="200px"
                    :src="driver.photo_dengan_sim == '' ? defautlPhoto() : driver.photo_dengan_sim"
                    alt="Cropped Image"
                  />
                </div>
              </section>
            </v-col>
            <!-- End Driver -->
            <!-- Toko -->
            <v-col md="6" cols="12" v-if="driver.id_jenisusaha != '1a2040e8-c61f-4d66-a93e-057320e1ee6d'">
              <v-text-field v-model="tokos.nama_toko" label="Nama Toko" class="required" dense outlined></v-text-field>
            </v-col>

            <v-col md="6" cols="12" v-if="driver.id_jenisusaha != '1a2040e8-c61f-4d66-a93e-057320e1ee6d'">
              <v-text-field
                v-model="tokos.alamat_toko"
                label="Alamat Toko"
                class="required"
                dense
                outlined
              ></v-text-field>
            </v-col>

            <v-col md="6" cols="12" v-if="driver.id_jenisusaha != '1a2040e8-c61f-4d66-a93e-057320e1ee6d'">
              <v-text-field
                id="nama_kelurahantoko"
                v-model="tokos.nama_kelurahan"
                class="required"
                data-bs-toggle="modal"
                data-bs-target="#ModalKelurahanToko"
                label="Kelurahan"
                dense
                outlined
                readonly
              ></v-text-field>

              <text
                id="id_kelurahantoko"
                v-model="tokos.id_kelurahan"
                class="required"
                data-bs-toggle="modal"
                data-bs-target="#ModalKelurahanToko"
                label="Kelurahan"
                dense
                outlined
                readonly
                type="hidden"
              ></text>
            </v-col>

            <v-col md="12" cols="12" v-if="driver.id_jenisusaha != '1a2040e8-c61f-4d66-a93e-057320e1ee6d'">
              <div id="mapContainerToko" style="width: 100%; height: 480px"></div>
            </v-col>

            <v-col md="6" cols="12" v-if="driver.id_jenisusaha != '1a2040e8-c61f-4d66-a93e-057320e1ee6d'">
              <v-col md="6" cols="12">
                <v-header>Longitude Toko</v-header>
              </v-col>
              <v-text-field
                id="lngtoko"
                v-model="tokos.lngtoko"
                class="required"
                dense
                outlined
                readonly
              ></v-text-field>
            </v-col>

            <v-col md="6" cols="12" v-if="driver.id_jenisusaha != '1a2040e8-c61f-4d66-a93e-057320e1ee6d'">
              <v-col md="6" cols="12">
                <v-header>Latitude Toko</v-header>
              </v-col>
              <v-text-field
                id="lattoko"
                v-model="tokos.lattoko"
                class="required"
                dense
                outlined
                readonly
              ></v-text-field>
            </v-col>

            <v-col md="6" cols="12" v-if="driver.id_jenisusaha != '1a2040e8-c61f-4d66-a93e-057320e1ee6d'">
              <v-text-field
                v-model="tokos.no_rekening"
                label="Nomor Rekening"
                class="required"
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col md="6" cols="12" v-if="driver.id_jenisusaha != '1a2040e8-c61f-4d66-a93e-057320e1ee6d'">
              <v-text-field v-model="tokos.nama_bank" label="Nama Bank" class="required" dense outlined></v-text-field>
            </v-col>

            <v-col md="12" cols="12" v-if="driver.id_jenisusaha != '1a2040e8-c61f-4d66-a93e-057320e1ee6d'">
              <v-card-text class="text-no-wrap pt-1 ps-1"> Logo </v-card-text>
              <div class="cropper-area">
                <div class="img-cropper">
                  <vue-cropper ref="cropperLogo" :aspect-ratio="4 / 4" :src="showLogo()" preview=".preview" />
                </div>
              </div>
              <v-btn type="submit" color="primary" class="me-3 mt-4" @click.prevent="showFileChooserLogo">
                Choose Logo
              </v-btn>
              <v-btn type="submit" color="primary" class="me-3 mt-4" @click.prevent="cropImageLogo"> Crop </v-btn>

              <input
                ref="inputLogo"
                style="display: none"
                type="file"
                :name="tokos.logo_toko"
                accept="image/*"
                @change="setImageLogo"
              />
              <br />
              <br />
              <v-card-text class="text-no-wrap pt-1 ps-1"> Crop Logo</v-card-text>
              <section class="preview-area">
                <div class="cropped-image">
                  <img
                    width="200px"
                    :src="tokos.logo_toko == '' ? defautlPhoto() : tokos.logo_toko"
                    alt="Cropped Image"
                  />
                </div>
              </section>
            </v-col>
            <hr />
            <v-col md="12" cols="12" v-if="driver.id_jenisusaha != '1a2040e8-c61f-4d66-a93e-057320e1ee6d'">
              <v-card-text class="text-no-wrap pt-1 ps-1"> Photo Toko </v-card-text>
              <div class="cropper-area">
                <div class="img-cropper">
                  <vue-cropper ref="cropperPhotoToko" :aspect-ratio="6 / 4" :src="showPhotoToko()" preview=".preview" />
                </div>
              </div>

              <v-btn type="submit" color="primary" class="me-3 mt-4" @click.prevent="showFileChooserPhotoToko">
                Choose Photo Toko
              </v-btn>
              <v-btn type="submit" color="primary" class="me-3 mt-4" @click.prevent="cropImagePhotoToko"> Crop </v-btn>

              <input
                ref="inputPhotoToko"
                style="display: none"
                type="file"
                :name="tokos.photo_toko"
                accept="image/*"
                @change="setImagePhotoToko"
              />
              <br />
              <br />
              <v-card-text class="text-no-wrap pt-1 ps-1"> Crop Photo Toko</v-card-text>
              <section class="preview-area">
                <div class="cropped-image">
                  <img
                    width="200px"
                    :src="tokos.photo_toko == '' ? defautlPhoto() : tokos.photo_toko"
                    alt="Cropped Image"
                  />
                </div>
              </section>
            </v-col>
            <!-- End Toko -->

            <v-col md="6" cols="12">
              <v-text-field
                id="nama_kategorimember"
                v-model="member.nama_kategorimember"
                class="required"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                label="Kategori Member"
                dense
                outlined
                readonly
              ></v-text-field>
              <text
                id="id_kategorimember"
                v-model="member.id_kategorimember"
                class="required"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                label="Kategori Member"
                dense
                outlined
                readonly
                type="hidden"
              ></text>
            </v-col>
            <v-col md="6" cols="12">
              <v-text-field
                v-model="member.nama_lengkap"
                label="Nama Lengkap"
                class="required"
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col md="6" cols="12">
              <v-header>Jenis Kelamin</v-header>
              <v-radio-group v-model="member.jenis_kelamin" row>
                <v-radio label="Laki-laki" value="L" color="info"></v-radio>
                <v-radio label="Perempuan" value="P" color="error"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col md="6" cols="12">
              <v-text-field v-model="member.no_ktp" label="No KTP" class="required" dense outlined></v-text-field>
            </v-col>
            <v-col md="6" cols="12">
              <v-text-field v-model="member.alamat" label="Alamat" class="required" dense outlined></v-text-field>
            </v-col>
            <v-col md="6" cols="12">
              <v-text-field
                id="nama_kelurahan"
                v-model="member.nama_kelurahan"
                class="required"
                data-bs-toggle="modal"
                data-bs-target="#ModalKelurahan"
                label="Kelurahan"
                dense
                outlined
                readonly
              ></v-text-field>
              <text
                id="id_kelurahan"
                v-model="member.id_kelurahan"
                class="required"
                data-bs-toggle="modal"
                data-bs-target="#ModalKelurahan"
                label="Kelurahan"
                dense
                outlined
                readonly
                type="hidden"
              ></text>
            </v-col>
            <v-col md="12" cols="12">
              <div id="mapContainer" style="width: 100%; height: 480px"></div>
            </v-col>
            <v-col md="6" cols="12">
              <v-col md="6" cols="12">
                <v-header>Longitude</v-header>
              </v-col>
              <v-text-field id="lng" v-model="member.lng" class="required" dense outlined readonly></v-text-field>
            </v-col>
            <v-col md="6" cols="12">
              <v-col md="6" cols="12">
                <v-header>Latitude</v-header>
              </v-col>
              <v-text-field id="lat" v-model="member.lat" class="required" dense outlined readonly></v-text-field>
            </v-col>
            <v-col md="6" cols="12">
              <v-header>Agama</v-header>
              <v-radio-group v-model="member.agama" row>
                <v-radio label="Islam" value="Islam" color="info"></v-radio>
                <v-radio label="Kristen" value="Kristen" color="info"></v-radio>
                <v-radio label="Katholik" value="Katholik" color="info"></v-radio>
                <v-radio label="Hindu" value="Hindu" color="info"></v-radio>
                <v-radio label="Budha" value="Budha" color="info"></v-radio>
                <v-radio label="Konghucu" value="Konghucu" color="info"></v-radio>
                <v-radio label="Kepercayaan" value="Kepercayaan" color="info"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col md="6" cols="12">
              <v-text-field
                :rules="[rulesemail.email]"
                v-model="member.email"
                label="Email"
                :prepend-inner-icon="icons.mdiEmailOutline"
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col md="6" cols="12">
              <v-text-field
                v-model="member.telp"
                label="Telp (tanpa 062 / +62)"
                :prepend-inner-icon="icons.mdiCellphone"
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col md="6" cols="12">
              <v-text-field
                v-model="member.whatsapp"
                label=" Whatsapp (tanpa 062 / +62)"
                :prepend-inner-icon="icons.mdiCellphone"
                dense
                outlined
              ></v-text-field>
            </v-col>

            <v-col md="6" cols="12">
              <v-card-text class="text-no-wrap pt-1 ps-1"> Photo </v-card-text>
              <div class="cropper-area">
                <div class="img-cropper">
                  <vue-cropper ref="cropper" :aspect-ratio="2 / 2" :src="showPhoto()" preview=".preview" />
                </div>
              </div>
              <v-btn type="submit" color="primary" class="me-3 mt-4" @click.prevent="showFileChooser">
                Choose Image
              </v-btn>
              <v-btn type="submit" color="primary" class="me-3 mt-4" @click.prevent="cropImage"> Crop </v-btn>
              <input
                ref="input"
                style="display: none"
                type="file"
                :name="member.photo"
                accept="image/*"
                @change="setImage"
              />
              <br />
              <br />
              <v-card-text class="text-no-wrap pt-1 ps-1"> Crop Photo </v-card-text>
              <section class="preview-area">
                <div class="cropped-image">
                  <img width="200px" :src="member.photo == '' ? defautlPhoto() : member.photo" alt="Cropped Image" />
                </div>
              </section>
            </v-col>

            <v-col cols="12">
              <v-btn type="submit" color="primary" class="me-3 mt-4"> Save </v-btn>
              <router-link :to="{ name: 'members-index' }">
                <v-btn color="secondary" outlined class="mt-4" type="reset"> Cancel </v-btn>
              </router-link>
            </v-col>
          </v-row>
        </form>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { mdiCheckBold, mdiEmailOutline, mdiCellphone, mdiCamera, mdiSlashForward } from '@mdi/js'
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'

export default {
  components: {
    VueCropper,
  },
  data() {
    return {
      breadcrumbs: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/admin/dashboard',
        },
        {
          text: 'Member',
          disabled: false,
          href: '/admin/members',
        },
        {
          text: 'Create Member',
          disabled: true,
        },
      ],

      icons: {
        mdiCheckBold,
        mdiEmailOutline,
        mdiCellphone,
        mdiCamera,
        mdiSlashForward,
      },
      rulesimage: [value => !value || value.size < 2000000 || 'Photo size should be less than 2 MB!'],

      rulesemail: {
        // required: value => !!value || 'Required.',
        // counter: value => value.length <= 20 || 'Max 20 characters',
        email: value => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
      },

      member: {
        id_kategorimember: '',
        no_ktp: '',
        nama_lengkap: '',
        alamat: '',
        id_kelurahan: '',
        jenis_kelamin: 'L',
        agama: 'Islam',
        email: '',
        telp: '',
        whatsapp: '',
        photo: '',
        lat: '',
        lng: '',
      },

      driver: {
        no_platmotor: '',
        merk_kendaraan: '',
        tahun_pembuatan_kendaraan: '',
        photo_sim: '',
        photo_dengan_sim: '',
        no_rekening: '',
        nama_bank: '',
        aktif: 'Y',
        blokir: 'T',
        id_jenisusaha: 'd001e490-e5e7-4a86-9359-0c5e8fb17ee6',
      },

      tokos: {
        nama_toko: '',
        nama_kelurahan: '',
        id_kelurahan: '',
        lattoko: '',
        lngtoko: '',
        logo_toko: '',
        no_rekening: '',
        nama_bank: '',
        photo_toko: '',
      },

      merkmotor: ['Honda', 'Yamaha', 'Suzuki', 'Kawasaki', 'Minerva', 'Vespa'],

      errors: [],

      jenisusaha: [],
      kategorimember: [],
      kelurahan: [],

      page: 1,
      perPage: 10,
      pages: [],
      search: '',
      searchResult: [],

      pageNegaraAsal: 1,
      perPageNegaraAsal: 10,
      pagesNegaraAsal: [],
      searchNegaraAsal: '',
      searchResultNegaraAsal: [],

      pageKelurahanToko: 1,
      perPageKelurahanToko: 10,
      pagesKelurahanToko: [],
      searchKelurahanToko: '',
      searchResultKelurahanToko: [],
    }
  },
  watch: {
    kategorimember() {
      this.pages = []
      this.setPages()
    },

    kelurahan() {
      this.pagesNegaraAsal = []
      this.setPagesDataNegaraAsal()
    },

    kelurahantoko() {
      this.pagesNegaraAsal = []
      this.setPagesDataKelurahanToko()
    },
  },
  mounted() {
    this.getMapToko()
    this.getMapMember()
    this.$isLoading(true)

    setTimeout(() => {
      // this.defautlPhoto()
      this.getAllJenisUsaha()
      this.getKategoriMember()
      this.getKelurahan()

      this.$isLoading(false)
    }, 2000)
  },
  filters: {
    capitalize: function (data) {
      var capitalized = []
      data.split(' ').forEach(word => {
        capitalized.push(word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      })
      return capitalized.join(' ')
    },
  },
  methods: {
    getMapToko() {
      const { tokos } = this

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
          const localCoord = position.coords
          tokos.lattoko = localCoord.latitude
          tokos.lngtoko = localCoord.longitude

          // Initialize the platform object:
          const platform = new H.service.Platform({
            apikey: 'BVnA0J-kRSraSgL_On6Vn3MayVHlJn9A16APycW1FPA',
          })

          // Obtain the default map types from the platform object
          const defaultLayers = platform.createDefaultLayers()

          // Instantiate (and display) a map object:
          const map = new H.Map(document.getElementById('mapContainerToko'), defaultLayers.vector.normal.map, {
            zoom: 17,
            center: {
              lat: tokos.lattoko,
              lng: tokos.lngtoko,
            },
            pixelRatio: window.devicePixelRatio || 1,
          })
          window.addEventListener('resize', () => map.getViewPort().resize())

          const ui = H.ui.UI.createDefault(map, defaultLayers)
          const mapEvents = new H.mapevents.MapEvents(map)
          const behavior = new H.mapevents.Behavior(mapEvents)

          // Draggable marker function
          function addDragableMarker(map, behavior) {
            const inputLat = document.getElementById('lat')
            const inputLng = document.getElementById('lng')

            if (inputLat.value != '' && inputLng.value != '') {
              tokos.lattoko = inputLat.value
              tokos.lngtoko = inputLng.value
            }

            const marker = new H.map.Marker(
              {
                lat: tokos.lattoko,
                lng: tokos.lngtoko,
              },
              {
                volatility: true,
              },
            )
            marker.draggable = true
            map.addObject(marker)

            // disable the default draggability of the underlying map
            // and calculate the offset between mouse and target's position
            // when starting to drag a marker object
            map.addEventListener(
              'dragstart',
              ev => {
                const { target } = ev
                const pointer = ev.currentPointer
                if (target instanceof H.map.Marker) {
                  const targetPosition = map.geoToScreen(target.getGeometry())
                  target.offset = new H.math.Point(
                    pointer.viewportX - targetPosition.x,
                    pointer.viewportY - targetPosition.y,
                  )
                  behavior.disable()
                }
              },
              false,
            )

            // Listen to the drag event and move the position of the marker
            // and necessary
            map.addEventListener(
              'drag',
              ev => {
                const { target } = ev
                const pointer = ev.currentPointer
                if (target instanceof H.map.Marker) {
                  target.setGeometry(
                    map.screenToGeo(pointer.viewportX - target.offset.x, pointer.viewportY - target.offset.y),
                  )
                }
              },
              false,
            )

            // re-enable the default draggability of the underlying map
            // when dragging has complete
            map.addEventListener(
              'dragend',
              ev => {
                const { target } = ev
                if (target instanceof H.map.Marker) {
                  behavior.enable()
                  const resultCoord = map.screenToGeo(ev.currentPointer.viewportX, ev.currentPointer.viewportY)

                  // console.log(resultCoord)
                  tokos.lattoko = resultCoord.lat
                  tokos.lngtoko = resultCoord.lng
                }
              },
              false,
            )
          }

          // Call this function
          addDragableMarker(map, behavior)

          // window.action ada di create.blade.php
          if (window.action == 'submit') {
            addDragableMarker(map, behavior)
          }

          // Browse location codespace
          let spaces = []

          const fetchSpaces = function (latitude, longitude, radius) {
            return new Promise((resolve, reject) => {
              resolve(
                fetch(`/api/spaces?lat=${latitude}&lng=${longitude}&rad=${radius}`)
                  .then(res => res.json())
                  .then(data => {
                    data.forEach((value, index) => {
                      const marker = new H.map.Marker({
                        lat: value.latitude,
                        lng: value.longitude,
                      })
                      spaces.push(marker)
                    })
                  }),
              )
            })
          }

          function clearSpace() {
            map.removeObjects(spaces)
            spaces = []
          }

          function init(latitude, longitude, radius) {
            clearSpace()
            fetchSpaces(latitude, longitude, radius).then(() => {
              map.addObjects(spaces)
            })
          }

          if (window.action == 'browse') {
            map.addEventListener(
              'dragend',
              ev => {
                const resultCoord = map.screenToGeo(ev.currentPointer.viewportX, ev.currentPointer.viewportY)
                tokos.lattoko = resultCoord.lat
                tokos.lngtoko = resultCoord.lng
                init(resultCoord.lat, resultCoord.lng, 40)
              },
              false,
            )

            init(tokos.lattoko, tokos.lngtoko, 40)
          }
        })
      }

      this.tokos = tokos
    },
    getMapMember() {
      const { member } = this

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
          const localCoord = position.coords
          member.lat = localCoord.latitude
          member.lng = localCoord.longitude

          // Initialize the platform object:
          const platform = new H.service.Platform({
            apikey: 'BVnA0J-kRSraSgL_On6Vn3MayVHlJn9A16APycW1FPA',
          })

          // Obtain the default map types from the platform object
          const defaultLayers = platform.createDefaultLayers()

          // Instantiate (and display) a map object:
          const map = new H.Map(document.getElementById('mapContainer'), defaultLayers.vector.normal.map, {
            zoom: 17,
            center: {
              lat: member.lat,
              lng: member.lng,
            },
            pixelRatio: window.devicePixelRatio || 1,
          })
          window.addEventListener('resize', () => map.getViewPort().resize())

          const ui = H.ui.UI.createDefault(map, defaultLayers)
          const mapEvents = new H.mapevents.MapEvents(map)
          const behavior = new H.mapevents.Behavior(mapEvents)

          // Draggable marker function
          function addDragableMarker(map, behavior) {
            const inputLat = document.getElementById('lat')
            const inputLng = document.getElementById('lng')

            if (inputLat.value != '' && inputLng.value != '') {
              member.lat = inputLat.value
              member.lng = inputLng.value
            }

            const marker = new H.map.Marker(
              {
                lat: member.lat,
                lng: member.lng,
              },
              {
                volatility: true,
              },
            )
            marker.draggable = true
            map.addObject(marker)

            // disable the default draggability of the underlying map
            // and calculate the offset between mouse and target's position
            // when starting to drag a marker object
            map.addEventListener(
              'dragstart',
              ev => {
                const { target } = ev
                const pointer = ev.currentPointer
                if (target instanceof H.map.Marker) {
                  const targetPosition = map.geoToScreen(target.getGeometry())
                  target.offset = new H.math.Point(
                    pointer.viewportX - targetPosition.x,
                    pointer.viewportY - targetPosition.y,
                  )
                  behavior.disable()
                }
              },
              false,
            )

            // Listen to the drag event and move the position of the marker
            // and necessary
            map.addEventListener(
              'drag',
              ev => {
                const { target } = ev
                const pointer = ev.currentPointer
                if (target instanceof H.map.Marker) {
                  target.setGeometry(
                    map.screenToGeo(pointer.viewportX - target.offset.x, pointer.viewportY - target.offset.y),
                  )
                }
              },
              false,
            )

            // re-enable the default draggability of the underlying map
            // when dragging has complete
            map.addEventListener(
              'dragend',
              ev => {
                const { target } = ev
                if (target instanceof H.map.Marker) {
                  behavior.enable()
                  const resultCoord = map.screenToGeo(ev.currentPointer.viewportX, ev.currentPointer.viewportY)

                  // console.log(resultCoord)
                  member.lat = resultCoord.lat
                  member.lng = resultCoord.lng
                }
              },
              false,
            )
          }

          // Call this function
          addDragableMarker(map, behavior)

          // window.action ada di create.blade.php
          if (window.action == 'submit') {
            addDragableMarker(map, behavior)
          }

          // Browse location codespace
          let spaces = []

          const fetchSpaces = function (latitude, longitude, radius) {
            return new Promise((resolve, reject) => {
              resolve(
                fetch(`/api/spaces?lat=${latitude}&lng=${longitude}&rad=${radius}`)
                  .then(res => res.json())
                  .then(data => {
                    data.forEach((value, index) => {
                      const marker = new H.map.Marker({
                        lat: value.latitude,
                        lng: value.longitude,
                      })
                      spaces.push(marker)
                    })
                  }),
              )
            })
          }

          function clearSpace() {
            map.removeObjects(spaces)
            spaces = []
          }

          function init(latitude, longitude, radius) {
            clearSpace()
            fetchSpaces(latitude, longitude, radius).then(() => {
              map.addObjects(spaces)
            })
          }

          if (window.action == 'browse') {
            map.addEventListener(
              'dragend',
              ev => {
                const resultCoord = map.screenToGeo(ev.currentPointer.viewportX, ev.currentPointer.viewportY)
                member.lat = resultCoord.lat
                member.lng = resultCoord.lng
                init(resultCoord.lat, resultCoord.lng, 40)
              },
              false,
            )

            init(member.lat, member.lng, 40)
          }
        })
      }

      this.member = member
    },
    ClearData() {
      // console.log('DATA AKTIF : ' + this.driver.id_jenisusaha)
      if (this.driver.id_jenisusaha != '1a2040e8-c61f-4d66-a93e-057320e1ee6d') {
        this.driver.no_platmotor = ''
        this.driver.merk_kendaraan = ''
        this.driver.tahun_pembuatan_kendaraan = ''
        this.driver.photo_sim = ''
        this.driver.photo_dengan_sim = ''
        this.driver.no_rekening = ''
        this.driver.nama_bank = ''
        this.getMapToko()
      } else {
        this.tokos.nama_toko = ''
        this.tokos.nama_kelurahan = ''
        this.tokos.id_kelurahan = ''
        this.tokos.lattoko = ''
        this.tokos.lngtoko = ''
        this.tokos.logo_toko = ''
        this.tokos.no_rekening = ''
        this.tokos.nama_bank = ''
        this.tokos.photo_toko = ''
      }
    },
    getAllJenisUsaha() {
      //state token
      localStorage.getItem('token')

      let url = process.env.VUE_APP_ROOT_API + '/api/admin/jenisusahas'
      return this.axios.get(url).then(response => {
        this.jenisusaha = response.data.jenisusahas
      })
    },
    getKategoriMember() {
      // state token
      localStorage.getItem('token')

      const url = `${process.env.VUE_APP_ROOT_API}/api/admin/kategorimember/penjual`

      return this.axios.get(url).then(response => {
        this.kategorimember = response.data.kategorimembers
      })
    },

    getKelurahan() {
      // state token
      localStorage.getItem('token')

      const uriNegara = `${process.env.VUE_APP_ROOT_API}/api/admin/kelurahans`

      return this.axios.get(uriNegara).then(response => {
        this.kelurahan = response.data.kelurahans
      })
    },

    defautlPhoto() {
      const imageDefault = `${process.env.VUE_APP_ROOT_API}/images/member/default_img.png`

      return imageDefault
    },

    showPhoto() {
      if (this.member.photo.length == 0) {
        const imageDefault = `${process.env.VUE_APP_ROOT_API}/images/member/default_img.png`

        return imageDefault
      }
      const imageClient = `${process.env.VUE_APP_ROOT_API}/${this.member.photo}`

      return imageClient
    },

    setImage(e) {
      const file = e.target.files[0]
      if (file.type.indexOf('image/') === -1) {
        alert('Please select an image file')

        return
      }
      if (typeof FileReader === 'function') {
        const reader = new FileReader()
        reader.onload = event => {
          this.member.photo = event.target.result

          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result)
        }
        reader.readAsDataURL(file)
      } else {
        alert('Sorry, FileReader API not supported')
      }
    },
    cropImage() {
      // get image data for post processing, e.g. upload or setting image src
      this.member.photo = this.$refs.cropper.getCroppedCanvas().toDataURL()
    },
    showFileChooser() {
      this.$refs.input.click()
    },

    // Photo SIM
    showPhotoSim() {
      if (this.driver.photo_sim.length == 0) {
        const imageDefault = `${process.env.VUE_APP_ROOT_API}/images/member/default_img.png`

        return imageDefault
      }
      const imageClient = `${process.env.VUE_APP_ROOT_API}/${this.driver.photo_sim}`

      return imageClient
    },

    setImageSim(e) {
      const file = e.target.files[0]
      if (file.type.indexOf('image/') === -1) {
        alert('Please select an image file')

        return
      }
      if (typeof FileReader === 'function') {
        const reader = new FileReader()
        reader.onload = event => {
          this.driver.photo_sim = event.target.result

          // rebuild cropperjs with the updated source
          this.$refs.cropperSim.replace(event.target.result)
        }
        reader.readAsDataURL(file)
      } else {
        alert('Sorry, FileReader API not supported')
      }
    },
    cropImageSim() {
      // get image data for post processing, e.g. upload or setting image src
      this.driver.photo_sim = this.$refs.cropperSim.getCroppedCanvas().toDataURL()
    },
    showFileChooserSim() {
      this.$refs.inputSim.click()
    },

    // Photo Bawa SIM
    showPhotoBawaSim() {
      if (this.driver.photo_dengan_sim.length == 0) {
        const imageDefault = `${process.env.VUE_APP_ROOT_API}/images/member/default_img.png`

        return imageDefault
      }
      const imageClient = `${process.env.VUE_APP_ROOT_API}/${this.driver.photo_dengan_sim}`

      return imageClient
    },

    setImageBawaSim(e) {
      const file = e.target.files[0]
      if (file.type.indexOf('image/') === -1) {
        alert('Please select an image file')

        return
      }
      if (typeof FileReader === 'function') {
        const reader = new FileReader()
        reader.onload = event => {
          this.driver.photo_dengan_sim = event.target.result

          // rebuild cropperjs with the updated source
          this.$refs.cropperBawaSim.replace(event.target.result)
        }
        reader.readAsDataURL(file)
      } else {
        alert('Sorry, FileReader API not supported')
      }
    },
    cropImageBawaSim() {
      // get image data for post processing, e.g. upload or setting image src
      this.driver.photo_dengan_sim = this.$refs.cropperBawaSim.getCroppedCanvas().toDataURL()
    },
    showFileChooserBawaSim() {
      this.$refs.inputBawaSim.click()
    },

    // Logo Toko
    showLogo() {
      if (this.tokos.logo_toko.length == 0) {
        const imageDefault = `${process.env.VUE_APP_ROOT_API}/images/member/default_img.png`

        return imageDefault
      }
      const imageClient = `${process.env.VUE_APP_ROOT_API}/${this.tokos.logo_toko}`

      return imageClient
    },

    setImageLogo(e) {
      const file = e.target.files[0]
      if (file.type.indexOf('image/') === -1) {
        alert('Please select an image file')

        return
      }
      if (typeof FileReader === 'function') {
        const reader = new FileReader()
        reader.onload = event => {
          this.tokos.logo_toko = event.target.result

          // rebuild cropperjs with the updated source
          this.$refs.cropperLogo.replace(event.target.result)
        }
        reader.readAsDataURL(file)
      } else {
        alert('Sorry, FileReader API not supported')
      }
    },
    cropImageLogo() {
      // get image data for post processing, e.g. upload or setting image src
      this.tokos.logo_toko = this.$refs.cropperLogo.getCroppedCanvas().toDataURL()
    },
    showFileChooserLogo() {
      this.$refs.inputLogo.click()
    },

    // Photo Toko
    showPhotoToko() {
      if (this.tokos.photo_toko.length == 0) {
        const imageDefault = `${process.env.VUE_APP_ROOT_API}/images/member/default_img.png`

        return imageDefault
      }
      const imageClient = `${process.env.VUE_APP_ROOT_API}/${this.tokos.photo_toko}`

      return imageClient
    },

    setImagePhotoToko(e) {
      const file = e.target.files[0]
      if (file.type.indexOf('image/') === -1) {
        alert('Please select an image file')

        return
      }
      if (typeof FileReader === 'function') {
        const reader = new FileReader()
        reader.onload = event => {
          this.tokos.photo_toko = event.target.result

          // rebuild cropperjs with the updated source
          this.$refs.cropperPhotoToko.replace(event.target.result)
        }
        reader.readAsDataURL(file)
      } else {
        alert('Sorry, FileReader API not supported')
      }
    },
    cropImagePhotoToko() {
      // get image data for post processing, e.g. upload or setting image src
      this.tokos.photo_toko = this.$refs.cropperPhotoToko.getCroppedCanvas().toDataURL()
    },
    showFileChooserPhotoToko() {
      this.$refs.inputPhotoToko.click()
    },

    CreateData(e) {
      // console.log('PHOTO: ' + this.clients.photo)
      if (this.$data.member.no_ktp.length === 0) {
        // this.errors.push('Nama Role harus diisi !')
        this.$swal.fire({
          title: 'Error',
          text: 'Nomor KTP required !',
          icon: 'error',
          showCancelButton: false,
          showConfirmButton: false,
          timer: 1000,
        })
      } else if (this.$data.member.alamat.length === 0) {
        this.$swal.fire({
          title: 'Error',
          text: 'Alamat required !',
          icon: 'error',
          showCancelButton: false,
          showConfirmButton: false,
          timer: 1000,
        })
      } else if (this.$data.member.id_kelurahan.length === 0) {
        this.$swal.fire({
          title: 'Error',
          text: 'Kelurahan required !',
          icon: 'error',
          showCancelButton: false,
          showConfirmButton: false,
          timer: 1000,
        })
      } else if (this.$data.member.email.length === 0) {
        this.$swal.fire({
          title: 'Error',
          text: 'Email required !',
          icon: 'error',
          showCancelButton: false,
          showConfirmButton: false,
          timer: 1000,
        })
      } else if (this.$data.driver.id_jenisusaha.length === 0) {
        this.$swal.fire({
          title: 'Error',
          text: 'Jenis usaha required !',
          icon: 'error',
          showCancelButton: false,
          showConfirmButton: false,
          timer: 1000,
        })
      } else {
        const uri = `${process.env.VUE_APP_ROOT_API}/api/admin/members/store`

        if (
          this.driver.id_jenisusaha == 'd001e490-e5e7-4a86-9359-0c5e8fb17ee6' ||
          this.driver.id_jenisusaha == '85ed9447-e397-4e9c-a254-55698bb0620f'
        ) {
          lngtoko = document.querySelector('#lngtoko').value
          lattoko = document.querySelector('#lattoko').value
          // console.log('LONGITUDE TOKO : ' + lngtoko)
        }
        this.axios
          .post(uri, {
            id_kategorimember: this.member.id_kategorimember,
            no_ktp: this.member.no_ktp,
            nama_lengkap: this.member.nama_lengkap,
            alamat: this.member.alamat,
            id_kelurahan: this.member.id_kelurahan,
            longitude: document.querySelector('#lng').value,
            latitude: document.querySelector('#lat').value,
            jenis_kelamin: this.member.jenis_kelamin,
            agama: this.member.agama,
            email: this.member.email,
            telp: this.member.telp,
            whatsapp: this.member.whatsapp,
            photo: this.member.photo,
            id_jenisusaha: this.driver.id_jenisusaha,

            no_platmotor: this.driver.no_platmotor,
            merk_kendaraan: this.driver.merk_kendaraan,
            tahun_pembuatan_kendaraan: this.driver.tahun_pembuatan_kendaraan,
            photo_sim: this.driver.photo_sim,
            photo_dengan_sim: this.driver.photo_dengan_sim,
            no_rekening: this.driver.no_rekening,
            nama_bank: this.driver.nama_bank,
            aktif: this.driver.aktif,
            blokir: this.driver.blokir,

            nama_toko: this.tokos.nama_toko,
            alamat_toko: this.tokos.alamat_toko,
            id_kelurahantoko: this.tokos.id_kelurahan,
            longitude_toko: lngtoko,
            latitude_toko: lattoko,
            logo_toko: this.tokos.logo_toko,
            no_rekeningtoko: this.tokos.no_rekening,
            nama_banktoko: this.tokos.nama_bank,
            photo_toko: this.tokos.photo_toko,
          })
          .then(response => {
            this.$swal.fire({
              title: 'Success',
              text: 'Member created successfully',
              icon: 'success',
              showCancelButton: false,
              showConfirmButton: false,
              timer: 1000,
            })

            setTimeout(() => {
              this.$router.push({ name: 'members-index' })
            }, 3000)
          })

        e.preventDefault()
      }
    },
    doSearch() {
      const go = this.kategorimember.filter(item =>
        this.search
          .toLowerCase()
          .split(' ')
          .every(v => item.nama_kategorimember.toLowerCase().includes(v)),
      )
      this.searchResult = go
      this.pages = []
      this.setPages()
    },
    displayData() {
      let paginate

      if (this.search == '') {
        paginate = this.paginate(this.kategorimember)
      } else {
        paginate = this.paginate(this.searchResult)
      }

      return paginate
    },
    setPages() {
      let numberOfPages

      if (this.search == '') {
        numberOfPages = Math.ceil(this.kategorimember.length / this.perPage)
      } else {
        numberOfPages = Math.ceil(this.searchResult.length / this.perPage)
      }
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index)
      }
    },
    paginate(kategorimember) {
      const { page } = this
      const { perPage } = this
      const from = page * perPage - perPage
      const to = page * perPage

      return kategorimember.slice(from, to)
    },

    doSearchNegaraAsal() {
      const go = this.kelurahan.filter(item =>
        this.searchNegaraAsal
          .toLowerCase()
          .split(' ')
          .every(v => item.nama_kelurahan.toLowerCase().includes(v)),
      )
      this.searchResultNegaraAsal = go
      this.pagesNegaraAsal = []
      this.setPagesDataNegaraAsal()
    },
    displayDataKeluarahan() {
      let paginateNegaraAsal

      if (this.searchNegaraAsal == '') {
        paginateNegaraAsal = this.paginateNegaraAsal(this.kelurahan)
      } else {
        paginateNegaraAsal = this.paginateNegaraAsal(this.searchResultNegaraAsal)
      }

      return paginateNegaraAsal
    },
    setPagesDataNegaraAsal() {
      let numberOfPages

      if (this.searchNegaraAsal == '') {
        numberOfPages = Math.ceil(this.kelurahan.length / this.perPageNegaraAsal)
      } else {
        numberOfPages = Math.ceil(this.searchResultNegaraAsal.length / this.perPageNegaraAsal)
      }
      for (let index = 1; index <= numberOfPages; index++) {
        this.pagesNegaraAsal.push(index)
      }
    },
    paginateNegaraAsal(kelurahan) {
      const { pageNegaraAsal } = this
      const { perPageNegaraAsal } = this
      const from = pageNegaraAsal * perPageNegaraAsal - perPageNegaraAsal
      const to = pageNegaraAsal * perPageNegaraAsal

      return kelurahan.slice(from, to)
    },

    // Kelurahan Toko
    doSearchKelurahanToko() {
      const go = this.kelurahan.filter(item =>
        this.searchKelurahanToko
          .toLowerCase()
          .split(' ')
          .every(v => item.nama_kelurahan.toLowerCase().includes(v)),
      )
      this.searchResultKelurahanToko = go
      this.pagesKelurahanToko = []
      this.setPagesDataKelurahanToko()
    },
    displayDataKeluarahanToko() {
      let paginateKelurahanToko

      if (this.searchKelurahanToko == '') {
        paginateKelurahanToko = this.paginateKelurahanToko(this.kelurahan)
      } else {
        paginateKelurahanToko = this.paginateKelurahanToko(this.searchResultKelurahanToko)
      }

      return paginateKelurahanToko
    },
    setPagesDataKelurahanToko() {
      let numberOfPages

      if (this.searchKelurahanToko == '') {
        numberOfPages = Math.ceil(this.kelurahan.length / this.perPageKelurahanToko)
      } else {
        numberOfPages = Math.ceil(this.searchResultKelurahanToko.length / this.perPageKelurahanToko)
      }
      for (let index = 1; index <= numberOfPages; index++) {
        this.pagesKelurahanToko.push(index)
      }
    },
    paginateKelurahanToko(kelurahan) {
      const { pageKelurahanToko } = this
      const { perPageKelurahanToko } = this
      const from = pageKelurahanToko * perPageKelurahanToko - perPageKelurahanToko
      const to = pageKelurahanToko * perPageKelurahanToko

      return kelurahan.slice(from, to)
    },

    PilihKategoriMember(id_kategorimember, nama_kategorimember) {
      document.querySelector('#id_kategorimember').setAttribute('value', id_kategorimember)
      document.querySelector('#nama_kategorimember').setAttribute('value', nama_kategorimember)
      this.member.id_kategorimember = id_kategorimember
      this.member.nama_kategorimember = nama_kategorimember
    },

    PilihKelurahan(id_kelurahan, nama_kelurahan) {
      document.querySelector('#id_kelurahan').setAttribute('value', id_kelurahan)
      document.querySelector('#nama_kelurahan').setAttribute('value', nama_kelurahan)
      this.member.id_kelurahan = id_kelurahan
      this.member.nama_kelurahan = nama_kelurahan
    },
    PilihKelurahanToko(id_kelurahan, nama_kelurahan) {
      document.querySelector('#id_kelurahantoko').setAttribute('value', id_kelurahan)
      document.querySelector('#nama_kelurahantoko').setAttribute('value', nama_kelurahan)
      this.tokos.id_kelurahan = id_kelurahan
      this.tokos.nama_kelurahan = nama_kelurahan
    },
  },
}
</script>
<style scoped>
.required >>> fieldset {
  border-color: rgba(250, 0, 29, 0.986);
}
</style>
